import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollPositionService {
  private positions: { [key: string]: number } = {};

  saveScrollPosition(route: string, position: number): void {
    this.positions[route] = position;
  }

  getScrollPosition(route: string): number | undefined {
    return this.positions[route];
  }
}