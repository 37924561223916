import { Component, Inject, isDevMode, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import translations from '../assets/language/de/translations.json';
import { NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { DOCUMENT, isPlatformBrowser, isPlatformServer, ViewportScroller } from '@angular/common';
import { concatAll, filter, map, shareReplay } from 'rxjs/operators';
import { RouterEventsService } from './core/services/router-events.service';
import * as marked from 'marked';
import { SubSink } from 'subsink';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ScrollPositionService } from './core/services/scroll-position.service';

export const AppConfig = {
  isHandset: false
};

@Component({
  selector: 'app-root',
  template: `
    <app-layout>
      <router-outlet></router-outlet>
    </app-layout>`

})

export class AppComponent implements OnInit {
  subs = new SubSink();

  title = 'flimmo-app';
  platformId;
  isBackNavi = false;

  // tslint:disable-next-line:ban-types
  constructor(private translate: TranslateService, @Inject(PLATFORM_ID) platformId: Object, router: Router, viewportScroller: ViewportScroller, private routerEventsService: RouterEventsService, private breakpointObserver: BreakpointObserver, private scrollPositionService: ScrollPositionService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');
    translate.setTranslation('de', translations);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');

    router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const route = router.url;
        const contentContainer = document.querySelector('mat-sidenav-content');
        const position = contentContainer.scrollTop;
        this.scrollPositionService.saveScrollPosition(route, position);

        this.isBackNavi = (event.navigationTrigger === 'popstate');
         


      });


    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
       
        const route = router.url;
        const contentContainer = document.querySelector('mat-sidenav-content');
        
        if (this.isBackNavi) {
          console.log('back navi')
        
          const savedPosition = this.scrollPositionService.getScrollPosition(route);
          if (savedPosition !== undefined) {
            setTimeout(() => {
              contentContainer.scrollTop = savedPosition;
            }, 300);

          } else {
            contentContainer.scrollTop = 0;
          }

        } else {
          contentContainer.scrollTop = 0;
        }
        
      });

   
    this.platformId = platformId;

    if (isPlatformServer(platformId)) {
      global['marked'] = marked.marked;
    }
  }

  

  isSearchURL(url): boolean {
    return url.startsWith('/?');
  }

  isDynamicPageContent(url): boolean {
    return url.startsWith('/redtext/') || url.startsWith('/special/') || url.startsWith('/besprechung/');
  }

  ngOnInit(): void {
    /**
     * Platformbrowser: if the platform is not browser localstorage is not available ( for prerender)
     */

    this.subs.sink = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map(result => result.matches),
        shareReplay()
      ).subscribe(value => {
        AppConfig.isHandset = value;
      });
  }
}
