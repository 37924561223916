<virtual-scroller #scroll [childHeight]="204" [items]="results" [bufferAmount]="400" [parentScroll]="scroll.window"
  fxLayout="column" class="example-viewport">
  <span #container fxLayout="row wrap" fxLayoutGap="grid" #row style="place-content: center!important;"
    [ngClass]="{'px20px': isHandset}">
    <span *ngFor="let m of scroll.viewPortItems; let i = index" fxLayout="row">
      <span fxLayout="column" [ngClass]="{'carousel-card': !isHandset, 'carousel-card-mob': isHandset}" [ngStyle]="{'width.px': isHandset ? cardWidthMobile : cardWidth,
                          'margin-bottom.px': verticalPadding,
                          'height.px': isHandset ? cardHeightMobile : cardHeight}" class="clickable" #items
        (click)="navTo(m, i)">
        <span class="h-100 position-relative overflow-hidden">
          <img [src]="m.imagelow ? (m.imagelow | safe: 'resourceUrl') : PLACEHOLDER_IMAGE_WITH_PATH" (error)="onImageError($event)"
            class="carousel-card-image">
          <span class="overlay-shade-b position-absolute overfill zero-pos">
            <span fxLayout="column" fxLayoutAlign="space-between" class="h-100 p14px">
              <span fxLayout="row" fxLayoutAlign="space-between">
                <span fxLayout="row">
                  <img *ngIf="m.rating === 4" [height]="35" [width]="35"
                    [alt]="'searchList.selectors.ratingOptions.4' | translate" ngSrc="assets/media/rating_badge.svg">
                  <img *ngIf="m.itemclass === sic.Ytbesprechung" [height]="35" [width]="35"
                    ngSrc="assets/media/ico_socialmedia.svg">
                </span>
                <span fxLayout="row" fxLayoutGap="6px">
                  <app-age-category-badge [width]="35" [height]="35" *ngIf="m.altersfreigabe && m.rating > 2"
                    [ageCategory]="m.altersfreigabe"></app-age-category-badge>
                  <app-traffic-lights [width]="18" [height]="46" [rating]="m.rating" class=""></app-traffic-lights>
                </span>

              </span>
              <span fxLayout="column" class="text-white">
                <span class="mx-1 f-preview-overline text-white">
                  {{m.itemclass === sic.Besprechung ? (m.genre | uppercase) : ""}}
                </span>
                <span class="mx-1 f-preview-headline text-white overflow-hidden ellipsis">
                  <a *ngIf="m.itemclass === sic.Besprechung" class="nice-link"
                    [routerLink]="['/besprechung', m.id , flimmoMeta.prettifyUrl(m.title)]">{{m.title}}</a>
                  <a *ngIf="m.itemclass === sic.Redtext" class="nice-link" [routerLink]="['/redtext', m.id]">
                    {{m.title}}</a>
                  <a *ngIf="m.itemclass === sic.Ytbesprechung" class="nice-link"
                    [routerLink]="['/ytbesprechung', m.id]">{{m.title}}</a>
                  <a *ngIf="m.itemclass === sic.Special" class="nice-link"
                    [routerLink]="['/special', m.id]">{{m.title}}</a>
                </span>
                <span *ngIf="hasSrc(m)" class="mx-1 f-preview-subline text-white src-box">
                  {{getText(m.srclist)}}
                </span>


              </span>
            </span>
          </span>

        </span>
      </span>
      <span *ngIf="(i + 1) % itemsPerRow !== 0 || i === 0;" class="filler"
        [ngClass]="{'filler': !isHandset,'filler-mobile': isHandset}"
        [ngStyle]="{'min-width.px': isHandset ? fillerMobile : filler}"></span>
    </span>
    <span *ngFor="let n of fillerCards; let i = index" fxLayout="row">
      <span fxLayout="column" [ngClass]="{'carousel-card': !isHandset, 'carousel-card-mob': isHandset}" [ngStyle]="{'width.px': isHandset ? cardWidthMobile : cardWidth,
                          'margin-bottom.px': verticalPadding,
                          'height.px': isHandset ? cardHeightMobile : cardHeight}" class="mb-1 clickable">
      </span>
      <span *ngIf="i+1 !== fillerCards.length" class="filler"
        [ngClass]="{'filler': !isHandset,'filler-mobile': isHandset}"
        [ngStyle]="{'min-width.px': isHandset ? fillerMobile : filler}"></span>
    </span>
  </span>
</virtual-scroller>
